import logo from './logo.svg';
import './App.css';
import ReservationCodePage from './components/ReservationCodePage';
import { ReactDOM } from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SuccessPage from './components/SuccessPage';



function App() {
  return (
    <div className="App">
      <header className="App-header">  
<BrowserRouter>
  <Routes>
    <Route path="/" element={<ReservationCodePage />}></Route>
    <Route path="/success" element={<SuccessPage />}></Route>

  </Routes>
</BrowserRouter>
      </header>
      </div>
  );
}

export default App;
