import * as React from 'react';
import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { Button, FormControl, Grid } from "@mui/material";
// import {useParams, useSearchParams} from "react-router-dom";
// import {getAllRooms, getUser, getUserRoom} from "./ReservationService";
// import * as PAGE from './PageConstants';
import FormHelperText from '@mui/material/FormHelperText';
import Logo from './Logo';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

import { ThemeProvider, createTheme } from '@mui/material/styles';

const themeCustom = createTheme({
    palette: {
        primary: {
            main: '#ffffff',
        },
        secondary: {
            main: '#ffffff', 
        }
    },
});

export default function ReservationCodePage() {
    let navigate = useNavigate();

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const [helperText, setHelperText] = React.useState('Номер Резервации с Эл. адрес');
    const [reservationCode, setReservationCode] = React.useState('');

    function handleInput(event) {
        setReservationCode(event.target.value.toUpperCase().trim());
    }
    function checkReservationCode() {
        if (!reservationCode || reservationCode.length !== 5) {
            setHelperText('Должен состоять из 5 символов');
            setError(true);
            return;
        }

        fetch('https://book.chanceyouth.org/api/retreatUsers/camp/reservation/9/' + reservationCode)
            .then((response) => response.json())
            .then((data) => {
                if (setData != null) {
                    setData(data);
                    if (data.reserved) {
                        setHelperText(data.firstName + ', your room is ' + data.roomNumber);
                        addGoogleSheets(data);
                    } else {
                        setHelperText(data.firstName + ", ROOM NOT RESERVED");
                    }
                } else {
                    setHelperText("Reservation not found");
                }
            })
            .catch((err) => {
                console.log(err.message);
            });

    }

    function addGoogleSheets(data) {
        const gary = {
            reservationCode: data.reservationCode,
            firstName: data.firstName,
            lastName: data.lastName,
            timeCheckedIn: Date()
        }
        axios.post('https://sheet.best/api/sheets/c7159c72-8737-456b-9b91-99019e07ac3a', gary)
            .then(response => {
                console.log(response);
            })
    }

    function handleSubmit(event) {
        event.preventDefault();
        checkReservationCode();


    }


    return (
        <ThemeProvider  theme={themeCustom}>
            <FormControl error={error} variant="standard">
                <Logo />
                <Grid component="form" container spacing={2} onSubmit={handleSubmit}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center">
                            <TextField sx={{ input: { color: 'white', borderColor: 'primary.main', borderRadius: 1 } }}
                                autoFocus={true} id="outlined-basic" onInput={handleInput}
                                value={reservationCode}
                                type="text"
                                label="Номер Резервации"
                                variant="outlined" />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent={"center"}>
                            <FormHelperText>{helperText}</FormHelperText>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center">
                            <Button variant="contained" type="submit">Check In</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </FormControl>
        </ThemeProvider>
    );
}

