import React, { PureComponent } from 'react'
import heavenSVG from "../img/home_logo.svg";
import '../Reservation.css';

export class Logo extends PureComponent {
  render() {
    return (
        <div id='logo'>
            <img style={{width:500}} id='heavenLogo' src={ heavenSVG }></img>
            <h1>IIIAHC Check In</h1>      
        </div>
    )
  }
}

export default Logo
