import React from 'react'

function SuccessPage(props) {
  return (
    <div>{props.fact}

    </div>
  )
}

export default SuccessPage